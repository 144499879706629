import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Context } from '../Context';

import '../css/products.scss';

const Products = () => {
  const { category } = useParams();
  let { products } = useContext(Context);
  products = products.filter((product) => product.category === category);

  // console.log(products);

  const productElements = products.map((product) => (
    <Link
      key={product.id}
      to={`/products/${category}/${product.id}`}
      className="product-tile"
    >
      <img src={product.images.front} alt="" />
      <h6>{product.name}</h6>
      <small>Line: {product.line}</small>
    </Link>
  ));

  return (
    <div id="products">
      <h4
        className="product-category-title"
        style={{ textTransform: category.length <= 3 && 'uppercase' }}
      >
        {category.replace('-', ' ')}
      </h4>
      <div className="products-grid">{productElements}</div>
    </div>
  );
};

export default Products;
