import React from 'react';
import JotformEmbed from 'react-jotform-embed';

import '../css/contact.scss';

const Contact = () => {
  return (
    <div>
      <h1 className="page-title">Contact</h1>
      <div className="contact-cards">
        <a href="tel:+27824493576">
          <div className="contact-card contact-phone">
            <i className="fas fa-phone-alt fa-2x"></i>
            <h2>Phone</h2>
            +27 82 449 3576
          </div>
        </a>
        <a href="mailto:steve@coltri.co.za">
          <div className="contact-card contact-mail">
            <i className="fas fa-envelope fa-2x"></i>
            <h2>E-mail</h2>
            steve@coltri.co.za
          </div>
        </a>
      </div>

      <JotformEmbed src="https://form.jotform.com/202323355569557" />
    </div>
  );
};

export default Contact;
