import React, { useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Context } from '../Context';

import '../css/productDetailsStyle.scss';

const ProductDetail = () => {
  const { products } = useContext(Context);
  const { category, productId } = useParams();
  const mainProduct = products.find((product) => product.id === productId);
  const certificateDropdown = useRef(null)

  const toggleCertificatesVisibility = () => {
    certificateDropdown.current.classList.toggle('show');
    console.log(certificateDropdown);
  }

  return (
    <div className="main-product-container">
      <h5 className="product-category">{category}</h5>
      <div className="main-product-display">
        <div className="image-section">
          <img
            src={mainProduct.images.front}
            alt={mainProduct.name}
            className="product-image"
          />
        </div>

        <div className="product-info">
          <h1>{mainProduct.name}</h1>
          <h5>Line: {mainProduct.line}</h5>
          {/* <h4>Description: </h4> */}
          <p>{mainProduct.description}</p>
        </div>
      </div>

      {/* Dimensions */}
      <div className="dimensions">
        <h3>Dimensions:</h3>
        <p>
          H: {mainProduct.dimensions.height} - W: {mainProduct.dimensions.width}{' '}
          - D: {mainProduct.dimensions.depth}
        </p>
      </div>

      {/* Table for product type */}
      <div className="additional-info">
        <h1>Table for product type</h1>
        <p className="main-title-sub">
          To understand which product, proceed as appropriate.
        </p>
        {mainProduct.tableForProductType.map((item) => (
          <div key={item.heading}>
            <h4>{item.heading}</h4>
            <small>{item.sub}</small>
            {item.info.map((item, index) => (
              <div key={index}>
                <h5>{Object.keys(item)}</h5>
                <p>{Object.values(item)}</p>
              </div>
            ))}
            <br />
          </div>
        ))}

        {mainProduct.certificates && 
        <div className="certifications">
          <div className="certificates-dropdown-button" onClick={toggleCertificatesVisibility}><span>Certificates</span> <i className="fas fa-chevron-down" /></div>
          <div className="certificates-dropdown" ref={certificateDropdown}>
            {mainProduct.certificates.map((item, index) => {
              return (
                <a href={item.url} className="certificate" key={index}>
                  <div>
                    <strong>{item.manufacturer}</strong>
                    <p>Lot nr. {item.lotnr}</p>
                  </div>
                  <div className="download">
                    <i className="fas fa-download"/> <span>Download</span>
                  </div>
                </a>
              )
            })}
          </div>
        </div>}
      </div>

      {/*  */}
      <div className="equipment-and-optionals">
        <div className="product-equipment">
          <h5>Equipment</h5>
          <br />
          {mainProduct.equipmentAndOptionals.equipment.map((item) => (
            <div key={item.name}>
              <h6>{item.name}</h6>
              <small>{item.info}</small>
            </div>
          ))}
        </div>

        <div className="product-optionals">
          <h5>Optionals</h5>
          <br />
          {mainProduct.equipmentAndOptionals.optionals.map((item) => (
            <div key={item.name}>
              <h6>{item.name}</h6>
              <small>{item.info}</small>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
