import React, { useState } from 'react';

const Context = React.createContext();

const ModalContextProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Context.Provider value={{ showModal, setShowModal }}>
      {children}
    </Context.Provider>
  );
};

export { ModalContextProvider, Context as ModalContext };
