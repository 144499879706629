import React /* useEffect, useState */ from 'react';
import navigation from './api/navigation.json';
import productCategories from './api/productCategories.json';
import products from './api/products.json';
import manuals from './api/manuals.json';
const Context = React.createContext();

const ContextProvider = ({ children }) => {
  // const [products, setProducts] = useState([]);
  // const [productCategories, setProductCategories] = useState(data);

  // useEffect(() => {}, []);

  return (
    <Context.Provider
      value={{ navigation, productCategories, products, manuals }}
    >
      {children}
    </Context.Provider>
  );
};

export { ContextProvider, Context };
