import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../Context';
import Carousel from '../components/Carousel';
import Map from '../components/Map';

import '../css/home.scss';

const Home = () => {
  const { productCategories } = useContext(Context);

  return (
    <div>
      {/* <div id="banner">
        <img
          src="https://images.unsplash.com/photo-1561983857-0a48c57dc564?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1047&q=80"
          alt=""
          style={{ width: '100%', height: 650, objectFit: 'cover' }}
        />
      </div> */}
      <Carousel />

      <div className="product-categories">
        {productCategories.map(({ title, path, image }) => (
          <Link to={path} key={path} className="products-category-card">
            <img src={image} alt="" />
            <h6>{title}</h6>
          </Link>
        ))}
      </div>

      <div className="title-products">
        <div className="title-line"></div>
        <h1>Products</h1>
      </div>

      <section className="section section-story">
        <h1 className="section-title title-story">
          Our <br />
          &nbsp;&nbsp;Story
        </h1>

        <article className="article-story">
          <h3>AEROTECNICA COLTRI® - HIGH PRESSURE BREATHING AIR COMPRESSORS</h3>
          <br />
          <p>
            Carlo and Giuseppe Coltri started manufacturing compressors in the
            late 1960s. Today, Aerotecnica Coltri Spa is one of the most
            important companies worldwide in the design and construction of{' '}
            <strong>
              high-pressure compressors for pure breathing air and technical
              gases
            </strong>
            .
          </p>
          <br />
          <p>
            We started with high-pressure compressors to refill diving
            cylinders, as used in recreational and military applications.
            Eventually, we added fire-fighting compressors for ground use, along
            with <strong>industrial gas compressors</strong> for nitrogen,
            argon, helium and Nitrox mixtures for diving equipment, with a
            maximum oxygen content of 40%.
          </p>
          <br />
          <p>
            Recently, with the increased proliferation of natural-gas vehicles,
            we have begun to manufacture compressors to refill the{' '}
            <strong>CNG cylinders</strong> used in such vehicles.
          </p>
        </article>
      </section>

      <section className="section section-location">
        <h1 className="section-title title-location">Location</h1>
        <div className="location-map">
          <Map />
        </div>
      </section>

      <div className="contact-guidance">
        <h2 style={{ textAlign: 'center' }}>
          Need assistance? Feel free to contact any time.
        </h2>
        <div>
          <a href="tel:0824493576" className="btn">
            <i className="fas fa-phone-alt"></i> &nbsp; 082 449 3576
          </a>
          <a href="mailto:steve@coltri.co.za" className="btn">
            <i className="fas fa-envelope"></i> &nbsp; steve@coltri.co.za
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
