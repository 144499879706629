import React from 'react';

const News = () => {
  return (
    <iframe
      src="https://www.coltri.com/en/news/#list_product"
      title="Coltri.com news page"
      frameborder="0"
      style={{ width: '100%', height: '80vh' }}
    ></iframe>
  );
};

export default News;
