import React from 'react';
import '../css/whatsapp.scss';

const Whatsapp = () => {
  return (
    <a
      className="whatsapp"
      href="https://api.whatsapp.com/send?phone=27824493576"
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className="whatsapp-text">Chat on Whatsapp</span> &nbsp;&nbsp;
      <i className="fab fa-whatsapp fa-2x"></i>
    </a>
  );
};

export default Whatsapp;
