import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Context } from '../Context';
import { ModalContext } from '../ModalContext';
import Modal from '../components/Modal';

import '../css/manuals.scss';

const Manuals = () => {
  const { manualsType } = useParams();
  let { manuals } = useContext(Context);
  const { showModal, setShowModal } = useContext(ModalContext);

  const [modalManual, setModalManual] = useState('');
  // const [showModal, setShowModal] = useState(false);

  // // Function which is called and shows modal on screen
  // const showModal = (item) => {
  //   console.log(item.url);
  //   return (
  //     // iFrame is wrapped in modal component
  //     <Modal>
  //       <iframe
  //         src={item.url}
  //         frameborder="0"
  //         style={{ width: '100vw', height: '100vh' }}
  //       ></iframe>
  //     </Modal>
  //   );
  // };

  // Generates array of 'cards' (manuals)
  const manualElements = manuals[manualsType].map((manual) => (
    // 'Card'
    <div
      className="manual-card"
      key={manual.id}
      // When clicked a modal component is returned with data (pdf url) from the specific 'card' clicked.
      onClick={() => {
        setShowModal(true);
        setModalManual(manual.url);
      }}
    >
      <img src={manual.thumbnail} alt="" />
      <h6>{manual.title}</h6>
      <small>{manual.category}</small>
    </div>
  ));

  return (
    // Displays a grid of 'cards' (or thumbnails)
    <div className="manuals-page">
      <h1 className="manuals-heading">
        {manualsType.replace('_', ' ').replace('_', ' ')}
      </h1>
      <div className="manuals">{manualElements}</div>
      {showModal && (
        <Modal>
          {manualsType === 'Video_Tutorials' ? (
            <iframe
              className="manual-iframe"
              // width="560"
              // height="315"
              style={{ minWidth: '100%', minHeight: '100%' }}
              src={modalManual}
              title="Video Tutorial"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <iframe
              className="manual-iframe"
              src={modalManual}
              title="Manual PDF"
              frameBorder="0"
              style={{ minWidth: '100%', minHeight: '100%' }}
              allowFullScreen
            ></iframe>
          )}
        </Modal>
      )}
    </div>
  );
};

export default Manuals;
