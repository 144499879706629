import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../Context';
import mapImg from '../images/map-image.png';

import '../css/footer.scss';

const Footer = () => {
  const { productCategories } = useContext(Context);

  return (
    <footer>
      <div id="map">
        <img src={mapImg} alt="" />
      </div>

      <div className="info">
        <div className="footer-navigation">
          <h3>Products</h3>
          <ul>
            {productCategories.map(({ title, path }) => (
              <li key={path}>
                <Link to={path}>{title}</Link>
              </li>
            ))}
          </ul>
        </div>

        <address className="footer-details">
          <p>
            <i className="fas fa-map-marker-alt"></i> &nbsp;{' '}
            <a href="https://www.google.com/maps/dir/?api=1&destination=Coltri+Compressors+South+Africa+(+Plantfocus+cc+)">
              Metro Works, Unit F3, Wakis Avenue, Strydompark, Randburg, 2169
            </a>
          </p>
          <p>
            <i className="far fa-clock"></i> &nbsp; Monday - Friday: 08:00 -
            16:30 | Saturday - Sunday: Closed
          </p>
          <p>
            <i className="fas fa-phone-alt"></i> &nbsp;{' '}
            <a href="tel:+27824493576">+27 82 449 3576</a> |{' '}
            <i className="fas fa-envelope"></i> &nbsp;{' '}
            <a href="mailto:steve@coltri.co.za">steve@coltri.co.za</a>
          </p>
          <a
            href="https://web.facebook.com/coltrisa"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook fa-2x"></i>
          </a>
          <p className="copyright">
            Coltri Compressors South Africa &copy; 2020
          </p>
        </address>
      </div>
    </footer>
  );
};

export default Footer;
