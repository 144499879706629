import React from 'react';
// import styles from '../css/generalStyles.module.scss';

const Map = ({ width = '100%', height = '100%', className }) => {
  // Map has an aspect ratio of 4:3
  // 500 : 375
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3583.633083050304!2d27.97258131518528!3d-26.07824516519797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950e836739a96d%3A0x4e848242a0ea5658!2sColtri%20Compressors%20South%20Africa%20(%20Plantfocus%20cc%20)!5e0!3m2!1sen!2sza!4v1597217561326!5m2!1sen!2sza"
      // width={width}
      // height={height}
      frameBorder="0"
      style={{
        border: 0,
        width,
        height,
      }}
      allowFullScreen=""
      aria-hidden="false"
      tabIndex="0"
      title="Google Map"
      className={className}
    ></iframe>
    // <iframe
    //   width="600"
    //   height="450"
    //   frameborder="0"
    //   style={{ border: 0 }}
    //   src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBsBj5hFIk5sg1xPlm5MUbojT2WUQrzq_o
    // &q=Space+Needle,Seattle+WA"
    //   allowfullscreen
    // ></iframe>
  );
};

export default Map;
