import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.css';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Products from './pages/Products';
import ProductDetail from './pages/ProductDetail';
import Manuals from './pages/Manuals';
import News from './pages/News';
import Contact from './pages/Contact';
import Whatsapp from './components/Whatsapp';

function App() {
  useEffect(() => {
    // window.addEventListener('scroll', (e) => console.log(window.scrollY));
    // return () => {
    //   // window.removeEventListener('scroll',)
    // };
  }, []);

  return (
    <main className="App">
      <Navbar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/products/:category">
          <Products />
        </Route>
        <Route path="/products/:category/:productId">
          <ProductDetail />
        </Route>
        <Route path="/manuals/:manualsType">
          <Manuals />
        </Route>
        <Route path="/news">
          <News />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
      </Switch>
      <Whatsapp />
      <Footer />
    </main>
  );
}

export default App;
