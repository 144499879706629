import React from 'react';
import image from '../images/coltri-banner_2.jpg';
import { Carousel as MyCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../css/carousel.scss';

const Carousel = () => {
  return (
    <MyCarousel
      autoPlay={true}
      showArrows={false}
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      stopOnHover={false}
      interval={4000}
      dynamicHeight={true}
    >
      <div className="carousel-slide">
        <img src={image} alt="" />
      </div>
      <div className="carousel-slide">
        <img
          src="https://images.unsplash.com/photo-1563062067-7700e1d9ae1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80"
          alt=""
        />
      </div>
      <div className="carousel-slide">
        <img
          src="https://images.unsplash.com/photo-1588432815128-363254491e4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80"
          alt=""
        />
      </div>
      <div className="carousel-slide">
        <img
          src="https://images.unsplash.com/photo-1561983857-0a48c57dc564?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1047&q=80"
          alt=""
        />
      </div>
    </MyCarousel>
  );
};

export default Carousel;

// http://react-responsive-carousel.js.org/storybook/?path=/story/01-basic--base&knob-showArrows_Toggles=true&knob-showStatus_Toggles=&knob-showIndicators_Toggles=true&knob-infiniteLoop_Toggles=true&knob-showThumbs_Toggles=&knob-useKeyboardArrows_Toggles=true&knob-autoPlay_Toggles=true&knob-stopOnHover_Toggles=&knob-swipeable_Toggles=true&knob-dynamicHeight_Toggles=true&knob-emulateTouch_Toggles=true&knob-thumbWidth_Values=100&knob-selectedItem_Values=0&knob-interval_Values=3000&knob-transitionTime_Values=150&knob-swipeScrollTolerance_Values=5
