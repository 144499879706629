import React, { useContext } from 'react';
import '../css/modal.scss';

import { ModalContext } from '../ModalContext';

const Modal = (props) => {
  const { setShowModal } = useContext(ModalContext);

  const handleClick = (e) => {
    if (e.target.className !== 'modal-content') {
      setShowModal(false);
    }
  };

  return (
    <div className="modal" onClick={(e) => handleClick(e)}>
      <div className="modal-content">
        <i className="fas fa-times" onClick={() => setShowModal(false)}></i>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
