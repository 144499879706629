import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../css/navbar.scss';

import navData from '../api/navigation.json';

import ColtriLogo from '../images/ColtriLogo.png';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [atHome, setAtHome] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { pathname } = useLocation();

  const handleOnScroll = () => {
    if (window.scrollY < 100) {
      setScrolled(false);
    } else {
      setScrolled(true);
    }
  };

  const toggleMenu = () => {
    setShowMenu((prevShowMenu) => !prevShowMenu);
  };

  const navTree = (nav) => {
    return nav.map(({ title, path, sub }) => {
      if (!sub.length) {
        return (
          <li onClick={() => setShowMenu(false)} key={title} className="">
            <Link to={path}>{title}</Link>
          </li>
        );
      } else {
        return (
          <li key={title} className="dropdown">
            {title}
            <ul className="dropdown-list">{navTree(sub)}</ul>
          </li>
        );
      }
    });
  };

  useEffect(() => {
    if (pathname === '/') {
      setAtHome(true);
      window.addEventListener('scroll', handleOnScroll);
    } else {
      setAtHome(false);
      window.removeEventListener('scroll', handleOnScroll);
    }
  }, [pathname]);

  return (
    <nav
      style={{
        ...(atHome && !scrolled ? transparentNavStyle : solidNavStyle),
        position: atHome ? 'fixed' : 'sticky',
      }}
    >
      {/* Nav image */}
      <Link to="/">
        <img
          src={ColtriLogo}
          alt="Coltri Logo"
          className={atHome && !scrolled ? 'moved-logo' : ''}
        />
      </Link>

      {/* Hamburger menu button */}
      <i
        className={`fas fa-${!showMenu ? 'bars' : 'times'} fa-2x`}
        onClick={toggleMenu}
      ></i>

      {/* Main navigation list */}
      <ul className={`${showMenu && 'showMenu'} main-nav`}>
        <li className="main-nav-item">
          <a href="https://coltri.co.za/shop">Shop</a>
        </li>
        {navTree(navData)}
        {/* <li className="main-nav-item dropdown">
          Products
          <ul className="dropdown-list">
            <li>
              <Link to="/products/portable-compressors">
                Portable Compressors
              </Link>
            </li>
            <li>
              <Link to="/products/filling-stations">Filling Stations</Link>
            </li>
            <li>
              <Link to="/products/cng">CNG</Link>
            </li>
            <li>
              <Link to="/products/boosters">Boosters</Link>
            </li>
            <li>
              <Link to="/products/oxygen-boosters">Oxygen Boosters</Link>
            </li>
            <li>
              <Link to="/products/nitrox-compressors">Nitrox Compressors</Link>
            </li>
            <li>
              <Link to="/products/cylinders">Cylinders</Link>
            </li>
          </ul>
        </li>
        <li className="main-nav-item dropdown">
          Manuals
          <ul className="dropdown-list">
            <li>
              <Link to="/manuals/Use_and_Maintanace">Use and Maintenace</Link>
            </li>
            <li>
              <Link to="/manuals/Spare_Parts">Spare Parts</Link>
            </li>
            <li>
              <Link to="/manuals/Video_Tutorials">Video Tutorials</Link>
            </li>
          </ul>
        </li>
        <li className="main-nav-item">
          <Link to="/news">News</Link>
        </li>
        <li className="main-nav-item">
          <Link to="/contact">Contact Us</Link>
        </li> */}
      </ul>
    </nav>
  );
};

const transparentNavStyle = {
  backgroundColor: 'transparent',
  color: '#fff',
  position: 'fixed',
};

const solidNavStyle = {
  backgroundColor: '#fff',
  color: '#000',
  boxShadow: '0px 5px 20px 0px rgba(0,0,0,0.1)',
  position: 'sticky',
};

export default Navbar;
